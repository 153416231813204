'use client';

import { QRCodeCanvas } from 'qrcode.react';
import { Box } from '@mui/material';
import ThemeBasic from 'apps/customer-portal/utils/theme';

const QRGenerator = ({ text }: { text: string }) => {
	const imageSettings = {
		src: '/static/favicon/512x512.png',
		x: undefined,
		y: undefined,
		height: 24,
		width: 24,
		opacity: 1,
		excavate: true,
	};
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{text && (
				<QRCodeCanvas
					value={text}
					title='Copy Link'
					size={150}
					imageSettings={imageSettings}
					fgColor={ThemeBasic.palette.primary.main}
				/>
			)}
		</Box>
	);
};

export default QRGenerator;
