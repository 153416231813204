import { CopyAll } from '@mui/icons-material';
import {
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	Paper,
	Typography,
} from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { getLinkSharedWallet } from 'apps/customer-portal/app/lib/wallet/actions';
import { useNotification } from 'apps/customer-portal/components/ErrorContext';
import QRGenerator from 'apps/customer-portal/components/QRGenerator';
import React, { useEffect, useState } from 'react';

type Props = {
	lng: 'es' | 'en';
};

export const LinkShare = ({ lng }: Props) => {
	const [linkShared, setSharedLink] = useState<string>('');
	const { t } = useClientTranslation(lng, ['wallet']);
	const { setErrorNotification, setSuccessNotification } = useNotification();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchLinkSharedWallet = async () => {
			try {
				const response = await getLinkSharedWallet();
				setSharedLink(response.data.toString());
			} catch (error) {
				setErrorNotification(
					t(error instanceof Error ? error.message : 'unknownError')
				);
			} finally {
				setLoading(false);
			}
		};

		fetchLinkSharedWallet();
	}, []);

	const copyToClipboard = async (text: string): Promise<void> => {
		try {
			await navigator.clipboard.writeText(text);
			setSuccessNotification(t('linkCopiedSuccessfully'));
		} catch (error) {
			setErrorNotification(t('linkCopiedError'));
		}
	};

	return (
		<Paper
			elevation={2}
			sx={{
				mt: 2,
				p: { md: 4, xs: 1 },
				borderRadius: 5,
				overflow: { md: 'auto' },
			}}
		>
			<Container maxWidth='xl' disableGutters>
				<Grid container>
					<Grid item xs={12}>
						<Typography
							variant='ExplicationTextBold'
							fontSize='clamp(1rem, 3vw, 1.5rem)'
							mb={2}
						>
							{t('linkPaidPersonal')}
						</Typography>
						{loading && (
							<Box display='flex' justifyContent='center' mt={4}>
								<CircularProgress sx={{ color: 'white' }} />
							</Box>
						)}{' '}
					</Grid>
					{!loading && (
						<>
							<Grid item xs={8}>
								<Typography variant='ExplicationTextBold' mb={1}>
									{t('linkPaidPersonalDescription')}
								</Typography>
								<Box
									sx={{
										padding: 2,
										borderRadius: 5,
										backgroundColor: '#f7f7f7',
										border: '1px solid #ccc',
										boxShadow: '0px 0px 10px rgba(0, 0,0, 0.1)',
										width: '100%',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										mb: 2,
									}}
								>
									<Typography
										variant='ExplicationText'
										color='primary'
										sx={{
											width: '90%',
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									>
										{linkShared || t('walletNotFound')}
									</Typography>
									<Button
										title={t('copyLink')}
										variant='text'
										color='success'
										sx={{
											minWidth: 'auto',
											p: 0,
											border: 'none',
										}}
										onClick={() => copyToClipboard(linkShared)}
									>
										<CopyAll />
									</Button>
								</Box>
							</Grid>
							<Grid item xs={4}>
								<QRGenerator text={linkShared} />
							</Grid>
						</>
					)}
				</Grid>
			</Container>
		</Paper>
	);
};
