'use client';
import React, { MouseEvent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import { useNotification } from '../ErrorContext';
import { usePathname } from 'next/navigation';

import { ListItem, Menu, Typography } from '@mui/material';
import Link from 'next/link';
import { useClientTranslation, useSessionClient } from '@negotium/common';

import ThemeBasic from 'apps/customer-portal/utils/theme';
import {
	ArrowSvg,
	CartShop,
	CloseSessionSVG,
	Password,
	RecipientsSvg,
	UserSvg,
	WishListSvg,
} from '../Svgs';
import { getGeneralConfigurationActives } from '@negotium/libCustomer';
import { Wallet } from '@mui/icons-material';

export default function MenuProfile({ lng }: { lng: 'es' | 'en' }) {
	const { t } = useClientTranslation(lng, ['common']);
	const [expanded, setExpanded] = useState<boolean>(false);
	const pathname = usePathname();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const [data, setMenu] = useState<
		Array<{
			label: string;
			icon: JSX.Element;
			iconActive: JSX.Element;
			isLink: boolean;
			path?: string;
			pathCheck?: string;
			handlerClick?: () => void;
			validatePermission?: boolean;
		}>
	>([
		{
			label: 'profile',
			icon: (
				<Box
					sx={{
						width: '21px',
						height: '21px',
					}}
				>
					{' '}
					<UserSvg color={ThemeBasic.palette.secondary.main || ''} />
				</Box>
			),
			iconActive: (
				<Box
					sx={{
						width: '21px',
						height: '21px',
					}}
				>
					{' '}
					<UserSvg color={ThemeBasic.palette.white?.main || ''} />
				</Box>
			),
			isLink: true,
			path: '/account',
			pathCheck: `/${lng}/account`,
		},
		{
			label: 'wishList',
			icon: (
				<Box
					sx={{
						width: '21px',
						height: '21px',
					}}
				>
					<WishListSvg color={ThemeBasic.palette.secondary.main || ''} />
				</Box>
			),
			iconActive: (
				<Box
					sx={{
						width: '21px',
						height: '21px',
					}}
				>
					<WishListSvg color={ThemeBasic.palette.white?.main || ''} />
				</Box>
			),
			isLink: true,
			path: '/account/wishList',
			pathCheck: `/${lng}/account/wishList`,
		},
		{
			label: 'myRecipients',
			icon: <RecipientsSvg color={ThemeBasic.palette.secondary.main || ''} />,
			iconActive: (
				<RecipientsSvg color={ThemeBasic.palette.white?.main || ''} />
			),
			isLink: true,
			path: '/account/myRecipients',
			pathCheck: `/${lng}/account/myRecipients`,
		},
		{
			label: 'orderHistory',
			icon: <CartShop color={ThemeBasic.palette.secondary.main || ''} />,
			iconActive: <CartShop color={ThemeBasic.palette.white?.main || ''} />,
			isLink: true,
			path: '/account/orderHistory',
			pathCheck: `/${lng}/account/orderHistory`,
		},

		{
			label: 'changePassword',
			icon: <Password color={ThemeBasic.palette.secondary.main || ''} />,
			iconActive: <Password color={ThemeBasic.palette.white?.main || ''} />,
			isLink: true,
			path: '/account/changePassword',
			pathCheck: `/${lng}/account/changePassword`,
		},

		{
			label: 'logout',
			icon: (
				<Box
					sx={{
						width: '18px',
						height: '18px',
						ml: 0.5,
					}}
				>
					<CloseSessionSVG color={ThemeBasic.palette.secondary.main || ''} />
				</Box>
			),
			iconActive: (
				<Box
					sx={{
						width: '18px',
						height: '18px',
						ml: 0.5,
					}}
				>
					<CloseSessionSVG color={ThemeBasic.palette.white?.main || ''} />
				</Box>
			),
			isLink: true,
			path: '/account/closeSession',
			pathCheck: `/${lng}/account/closeSession`,
		},
	]);

	const handleOpen = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [activeMenu, setActiveMenu] = useState<{
		label: string;
		icon: JSX.Element;
	}>({
		label: 'profile',
		icon: (
			<Box
				sx={{
					width: '21px',
					height: '21px',
				}}
			>
				<UserSvg />
			</Box>
		),
	});

	const user = useSessionClient();

	useEffect(() => {
		if (expanded) {
			setExpanded(false);
		}

		const element = data.find((dt) => dt.pathCheck === pathname);
		if (element) {
			setActiveMenu({
				label: element.label,
				icon: element.iconActive,
			});
		}
	}, [pathname]);

	useEffect(() => {
		getGeneralConfigurationActives()
			.then((result) => {
				if (result.docs?.virtualWallet) {
					const newMenu = [...data];

					newMenu.splice(4, 0, {
						label: 'virtualWallet',
						icon: (
							<Wallet sx={{ color: ThemeBasic.palette.secondary.main || '' }} />
						),
						iconActive: (
							<Wallet sx={{ color: ThemeBasic.palette.white?.main || '' }} />
						),
						isLink: true,
						path: '/account/wallet',
						pathCheck: `/${lng}/account/wallet`,
					});
					setMenu([...newMenu]);
				}
			})
			.catch((err) => {
				console.log('🚀 ~ getGeneralConfigurationActives ~ err:', err);
			});
	}, []);

	const RenderMenu = (
		<List
			sx={{
				background: (theme) => theme.palette.white?.main || '',
				borderBottomLeftRadius: '10px',
				borderBottomRightRadius: '10px',
				py: {
					md: '8px',
					xs: '0 !important',
				},
			}}
		>
			{data.map((item) => {
				return item.isLink ? (
					<Link
						key={item.label}
						href={item.path || ''}
						style={{
							textDecoration: 'none',
						}}
					>
						<ListItem
							key={item.label}
							title={t(item.label)}
							sx={{
								pl: 2.1,

								backgroundColor: (theme) =>
									theme.palette[
										item.pathCheck === pathname ? 'secondary' : 'white'
									]?.main || '',
								color: (theme) =>
									theme.palette[
										item.pathCheck === pathname ? 'white' : 'secondary'
									]?.main || '',
								svg: {
									stroke: (theme) =>
										theme.palette[
											item.pathCheck === pathname ? 'white' : 'secondary'
										]?.main,
									fill: (theme) =>
										theme.palette[
											item.pathCheck === pathname ? 'white' : 'secondary'
										]?.main,
								},

								':hover': {
									backgroundColor: (theme) => theme.palette.primary.main,
									color: (theme) => theme.palette.white?.main || '',
									svg: {
										stroke: (theme) => theme.palette.secondary.main,
										fill: (theme) => theme.palette.white?.main || '',
									},
								},
							}}
						>
							<ListItemIcon
								sx={{
									color:
										item.pathCheck === pathname ? 'white !important' : 'black',
									svg: {
										fill: (theme) => theme.palette.secondary.main,
									},
									minWidth: '44px',
								}}
							>
								{item.icon}
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										variant='ExplicationTextBold'
										textTransform='initial'
										margin='0'
										sx={{
											color: (theme) =>
												theme.palette[
													item.pathCheck === pathname ? 'white' : 'secondary'
												]?.main || '',
										}}
									>
										{t(item.label)}
									</Typography>
								}
							/>
						</ListItem>
					</Link>
				) : (
					<ListItem
						key={item.label}
						title={t(item.label)}
						onClick={item.handlerClick}
						sx={{
							cursor: 'pointer',
							pl: 2.2,
							backgroundColor: (theme) =>
								theme.palette[
									item.pathCheck === pathname ? 'secondary' : 'white'
								]?.main || '',
							color: (theme) =>
								theme.palette[
									item.pathCheck === pathname ? 'white' : 'secondary'
								]?.main || '',
							svg: {
								stroke: (theme) =>
									theme.palette[
										item.pathCheck === pathname ? 'white' : 'secondary'
									]?.main,
								fill: (theme) =>
									theme.palette[
										item.pathCheck === pathname ? 'white' : 'secondary'
									]?.main,
							},

							':hover': {
								backgroundColor: (theme) => theme.palette.primary.main,
								color: (theme) => theme.palette.secondary.main,
								svg: {
									stroke: (theme) => theme.palette.secondary.main,
									fill: (theme) => theme.palette.secondary.main,
								},
							},
						}}
					>
						<ListItemIcon
							sx={{
								pl: '3px',
								color:
									item.pathCheck === pathname ? 'white !important' : 'black',
								svg: {
									fill: (theme) => theme.palette.secondary.main,
								},
								':hover': {
									color: 'white !important',
									svg: {
										fill: (theme) => theme.palette.secondary.main,
									},
								},
							}}
						>
							{item.icon}
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography
									variant='ExplicationTextBold'
									textTransform='initial'
									margin='0'
									sx={{
										color: (theme) =>
											theme.palette[
												item.pathCheck === pathname ? 'white' : 'secondary'
											]?.main || '',
										':hover': {
											color: (theme) =>
												theme.palette[
													item.pathCheck === pathname ? 'primary' : 'secondary'
												].main,
										},
									}}
								>
									{t(item.label)}
								</Typography>
							}
						/>
					</ListItem>
				);
			})}
		</List>
	);

	const RenderDesktop = (
		<List
			component='nav'
			disablePadding
			sx={{
				display: {
					md: 'block',
					xs: 'none',
				},
			}}
		>
			<ListItem
				sx={{
					py: 2,
					pl: 4.5,
					background: (theme) => theme.palette.secondary.main,
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<ListItemIcon>
					<AccountCircleIcon
						fontSize='medium'
						sx={{
							color: (theme) => theme.palette.white?.main,
						}}
					/>
				</ListItemIcon>
				<ListItemText
					primary={`${t('hello')}, ${user?.name} ${user?.lastName}`}
					primaryTypographyProps={{
						variant: 'ExplicationTextBold',
						fontSize: '14px',
						color: 'white.main',
					}}
				/>
			</ListItem>
			<Divider />
			{RenderMenu}
		</List>
	);
	const RenderMobile = (
		<Box
			sx={{
				width: '100%',
				position: 'relative',
				display: {
					xs: 'block',
					md: 'none',
				},
			}}
		>
			<Box
				sx={{
					background: (theme) => theme.palette.secondary?.main,
					borderRadius: '10px',
					display: 'flex',
					alignItems: 'center',
					padding: '18px',
					cursor: 'pointer',
					width: '100%',
				}}
				onClick={handleOpen}
			>
				{activeMenu.icon}
				<Typography
					variant='ExplicationTextBold'
					color='white.main'
					ml={2}
					fontSize='14px'
				>
					{t(activeMenu.label)}
				</Typography>
				<Box
					sx={{
						marginLeft: 'auto',
						width: '15px',
						height: '15px',
					}}
				>
					<ArrowSvg />
				</Box>
			</Box>

			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				PaperProps={{
					sx: {
						width: anchorEl ? `${anchorEl.offsetWidth}px` : 'auto',
						boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
						backdropFilter: 'blur(10px)',
						opacity: 0.97,
						padding: 0,
						zIndex: 99,
						borderRadius: '10px',
						borderTopRightRadius: 0,
						borderTopLeftRadius: 0,
					},
				}}
				sx={{
					ul: { py: 0 },
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{RenderMenu}
				</Box>
			</Menu>
		</Box>
	);

	return (
		<Box>
			<Paper
				elevation={3}
				sx={{
					width: '100%',
					borderRadius: 3,
					overflow: 'hidden',
				}}
			>
				{RenderDesktop}
				{RenderMobile}
			</Paper>
		</Box>
	);
}
