import { Close } from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Slide,
	useTheme,
	Typography,
	TextField,
	CircularProgress,
	Divider,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useClientTranslation } from '@negotium/common';
import { getExchangePtUser, getPointUser } from '@negotium/libCustomer';
import { useNotification } from 'apps/customer-portal/components/ErrorContext';
import { WalletSvg } from 'apps/customer-portal/components/Svgs';
import React, { useEffect, useState } from 'react';
import { Recommendations } from '../Recommendation';
import { redeemPointUserByBalanceWallet } from 'apps/customer-portal/app/lib/wallet/actions';

type Props = {
	lng: 'es' | 'en';
	open: boolean;
	onClose: () => void;
	onRefreshBalance: () => void;
};
const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});
export const RedeemPoints = ({
	lng,
	open,
	onClose,
	onRefreshBalance,
}: Props) => {
	const { t } = useClientTranslation(lng, ['wallet', 'common']);
	const theme = useTheme();
	const [pointUsers, setPointUsers] = useState<number>(0);
	const [loading, setLoading] = useState(true);
	const [pointToRedeem, setPointToRedeem] = useState<number>(0);
	const [balance, setBalance] = useState<number>(0);
	const [exchangePt, setExchangePt] = useState<number>(0);
	const { setErrorNotification, setSuccessNotification } = useNotification();

	useEffect(() => {
		const fetchPointUsers = async () => {
			try {
				const response = await getPointUser();
				const responseExchange = await getExchangePtUser();

				setPointUsers(response.data);
				setExchangePt(responseExchange.data);
			} catch (error) {
				setErrorNotification(
					error instanceof Error ? error.message : 'Unknown error'
				);
			} finally {
				setLoading(false);
			}
		};
		if (open) {
			fetchPointUsers();
		}
	}, [open]);

	function handlerChangePointToRedeem(value: number) {
		if (value >= 0 && value <= pointUsers) {
			setPointToRedeem(value);
			if (exchangePt) {
				setBalance(value / exchangePt);
			}
		}
	}

	async function handlerRedeemPoints() {
		try {
			await redeemPointUserByBalanceWallet(pointToRedeem);
			setSuccessNotification(t('walletRedeemPointsSuccess'));
			onClose();
			onRefreshBalance();
		} catch (error) {
			setErrorNotification(
				error instanceof Error ? error.message : 'Unknown error'
			);
		} finally {
			setLoading(false);
		}
	}

	return (
		<Dialog
			open={true}
			TransitionComponent={Transition}
			keepMounted
			maxWidth='sm'
			fullWidth
			onClose={onClose}
			aria-describedby='alert-dialog-slide-description'
			PaperProps={{
				sx: {
					backgroundColor: theme.palette.white?.main || 'white',
					py: 2,
					borderRadius: 6,
				},
			}}
			slotProps={{
				backdrop: {
					sx: {
						background: (theme) => theme.palette.secondary.light,

						backdropFilter: 'blur(10px)',
					},
				},
			}}
		>
			<DialogTitle>
				<Box
					display='flex'
					justifyContent='space-between'
					alignItems='flex-start'
				>
					<Typography
						variant='ExplicationTextBold'
						fontSize='clamp(1rem, 3vw, 1.4rem)'
					>
						{t('wantRedeemPoints')}
					</Typography>
					<Button
						onClick={() => onClose()}
						variant='contained'
						color='secondary'
						sx={{
							p: 0,
							minWidth: '30px',
							height: '30px',
							borderRadius: '50%',
						}}
					>
						<Close />
					</Button>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant='ExplicationText'>
							{t('redeemPointsDescription')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box
							sx={{
								padding: 4,
								background: (theme) => theme.palette.primary.main,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 2,
							}}
						>
							<Grid container>
								<Grid item xs={4}>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
											height: '100%',
										}}
									>
										<Typography
											variant='ExplicationText'
											fontSize='clamp(1rem, 3vw, 1.5rem)'
											color='white.main'
											textAlign='center'
											mt={0}
										>
											{t('redeemPointsAmount')}
										</Typography>
										{loading && (
											<Box display='flex' justifyContent='center' mt={4}>
												<CircularProgress sx={{ color: 'white' }} />
											</Box>
										)}
										{!loading && (
											<Typography
												mt={2}
												variant='ExplicationTextBold'
												fontSize='clamp(1rem, 3vw, 2.5rem)'
												color='white.main'
												textAlign='center'
											>
												{pointUsers}
											</Typography>
										)}
									</Box>
								</Grid>
								<Grid item xs={8}>
									<Box
										sx={{
											width: '25%',
											marginLeft: 'auto',
										}}
									>
										<WalletSvg />
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							id='outlined-basic'
							label={t('points')}
							variant='outlined'
							type='number'
							inputProps={{ min: 0, max: pointUsers }}
							onChange={(e) =>
								handlerChangePointToRedeem(Number(e.target.value))
							}
							value={pointToRedeem}
							sx={{
								width: '100%',
								marginBottom: 2,
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id='outlined-basic'
							label={t('balanceWallet')}
							variant='outlined'
							type='number'
							InputProps={{
								readOnly: true,
							}}
							value={balance}
							inputProps={{ min: 0 }}
							sx={{
								width: '100%',
								marginBottom: 2,
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
							}}
						>
							<Button
								variant='ButtonCancelDialog'
								color='error'
								onClick={() => onClose()}
							>
								{t('cancel', { ns: 'common' })}
							</Button>
							<Button
								variant='ButtonAcceptDialog'
								color='primary'
								sx={{
									ml: 1,
								}}
								onClick={() => handlerRedeemPoints()}
								disabled={
									pointToRedeem > pointUsers ||
									pointToRedeem <= 0 ||
									!exchangePt
								}
							>
								{t('redeem')}
							</Button>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Divider
							sx={{
								my: 1,
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Recommendations lng={lng} />
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};
