import { CopyAll } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { encryptAES, useClientTranslation } from '@negotium/common';
import { getBusinessRuleByType, usersGetProfile } from '@negotium/libCustomer';
import { IBusinessRulesShow } from '@negotium/models';
import { useNotification } from 'apps/customer-portal/components/ErrorContext';
import React, { useEffect, useState } from 'react';

type Props = {
	lng: 'es' | 'en';
};

export const Recommendations = ({ lng }: Props) => {
	const { t } = useClientTranslation(lng, ['businessRule']);
	const [businessRule, setBusinessRule] = useState<IBusinessRulesShow | null>(
		null
	);
	const [loading, setLoading] = useState<boolean>(true);
	const { setErrorNotification, setSuccessNotification } = useNotification();

	const [urlRecommend, setUrlRecommend] = useState<string>('');

	useEffect(() => {
		const fetchBusinessRuleByType = async () => {
			try {
				const response = await getBusinessRuleByType('byRecommendations');
				usersGetProfile()
					.then((result) => {
						if (result.data?.userId) {
							const encrypt = encryptAES(
								result.data.userId,
								process.env.NEXT_PUBLIC_ENCRYPT_KEY ||
									'U2FsdGVkX1+gixbKqLTIRISS1eGza2WfKVXdDSWRlKo='
							);

							const encryptBr = encryptAES(
								response?.data?.businessRuleId || '',
								process.env.NEXT_PUBLIC_ENCRYPT_KEY ||
									'U2FsdGVkX1+gixbKqLTIRISS1eGza2WfKVXdDSWRlKo='
							);
							setUrlRecommend(
								`${process.env.NEXT_PUBLIC_DOMAIN}/${lng}?signUp=true&referredLink=${encrypt}&br=${encryptBr}`
							);
						}
					})
					.catch((err) => {
						setErrorNotification(err.message);
					});
				setBusinessRule(response.data);
			} catch (error) {
				setErrorNotification(
					error instanceof Error ? error.message : 'Unknown error'
				);
			} finally {
				setLoading(false);
			}
		};
		if (loading) {
			fetchBusinessRuleByType();
		}
	}, []);

	if (loading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress sx={{ color: 'white' }} />
			</Box>
		);
	}

	if (!loading && !businessRule) {
		return null;
	}

	const copyToClipboard = async (text: string): Promise<void> => {
		try {
			await navigator.clipboard.writeText(text);
			setSuccessNotification(t('copiedToClipboardReferredLink'));
		} catch (error) {
			setErrorNotification(t('linkCopiedError'));
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography
					variant='ExplicationTextBold'
					fontSize='clamp(1rem, 3vw, 1.4rem)'
				>
					{businessRule?.name || t('doYouWantEarnSomePoints')}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant='ExplicationText'>
					{t('youCanEarnPointsBy')}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Box
					sx={{
						padding: 2,
						borderRadius: 5,
						backgroundColor: '#f7f7f7',
						border: '1px solid #ccc',
						boxShadow: '0px 0px 10px rgba(0, 0,0, 0.1)',
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						mb: 2,
					}}
				>
					<Typography
						variant='ExplicationText'
						color='primary'
						sx={{
							width: '90%',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{urlRecommend || t('walletNotFound')}
					</Typography>
					<Button
						title={t('copyLink')}
						variant='text'
						color='success'
						sx={{
							minWidth: 'auto',
							p: 0,
							border: 'none',
						}}
						onClick={() => copyToClipboard(urlRecommend)}
					>
						<CopyAll />
					</Button>
				</Box>
			</Grid>
		</Grid>
	);
};
